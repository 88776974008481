import React, { useEffect, useMemo, useState } from "react";
import "./HotelDetailsNav.scss";
import { useDispatch, useSelector } from "react-redux";
import AllReviews from "components/reviews/allReviews";
import styles from "../../../reviews/style.module.scss";
import { getPropertyReviewList } from "store/actions/ReviewActions";
import { AppDispatch } from "helpers/GenericType";

type Props = {
  flatId: string;
  showButton: boolean;
  showNav: boolean;
  showReserveButton: boolean;
  reviewData: any;
  handleReserve?: () => void;
};

const HotelDetailsNav: React.FC<Props> = ({
  flatId,
  showButton,
  showNav,
  showReserveButton,
  reviewData,
  handleReserve,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { hotel }: any = useSelector((state) => state);
  const [activeSection, setActiveSection] = useState<string>("");
  const availableFlat = hotel.hotelDetails.availableFlat || {};
  const [showMore, setShowMore] = useState(false);
  const [defaultReviewId, setDefaultReviewId] = useState(0);
  const [reviewDatas, setReviewDatas] = useState({
    totalReviews: 0,
    overallRating: 0,
    reviews: [],
    rating: [],
  });

  const handleScrollToSection = (id: string) => {
    const section = document.getElementById(id);
    const navHeight =
      (document.querySelector(".hotel-details-nav") as HTMLElement)
        ?.offsetHeight || 0;

    if (section) {
      window.scrollTo({
        top: section.offsetTop - navHeight,
        behavior: "smooth",
      });
    }
  };

  const handleScroll = () => {
    const sections = ["gallery", "rooms", "reviews", "location"];
    const currentScrollY = window.scrollY;

    for (const section of sections) {
      const element = document.getElementById(section);
      if (element) {
        const rect = element.getBoundingClientRect();
        if (rect.top <= 100 && rect.bottom >= 100) {
          setActiveSection(section);
          break;
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const getReviews = (flatId: string) => {
    dispatch(getPropertyReviewList(flatId))
      .then((res: any) => {
        setReviewDatas(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handleViewMore = (reviewId: number) => {
    setShowMore(true);
    if (reviewId) {
      setDefaultReviewId(reviewId);
    } else {
      setDefaultReviewId(0);
    }
  };

  const closeAllReview = () => {
    setShowMore(false);
    setDefaultReviewId(0);
  };
  const reviewWrapper = useMemo(
    () => (
      <div className={styles.headingWrapper}>
        <span>★</span>
        <span>{reviewData.overallRating || 0}</span>
        <span>·</span>
        <span>{reviewData.totalReviews || 0} Reviews</span>
      </div>
    ),
    [reviewDatas]
  );
  useEffect(() => {
    if (flatId) {
      getReviews(flatId);
    }
  }, [flatId]);

  return (
    <>
      <nav className={`hotel-details-nav ${showNav ? "visible" : "hidden"}`}>
        <div className="nav-container">
          <ul className="nav-links">
            <li
              className={activeSection === "gallery" ? "active" : ""}
              onClick={() => handleScrollToSection("gallery")}
            >
              Photos
            </li>
            <li
              className={activeSection === "amenities" ? "active" : ""}
              onClick={() => handleScrollToSection("amenities")}
            >
              Amenities
            </li>
            <li
              className={activeSection === "reviews" ? "active" : ""}
              onClick={() => handleScrollToSection("reviews")}
            >
              Reviews
            </li>
            <li
              className={activeSection === "location" ? "active" : ""}
              onClick={() => handleScrollToSection("location")}
            >
              Location
            </li>
          </ul>
          {showReserveButton && (
            <div className="reserve-container">
              <div className="price-review">
                <span className="price">
                  ₹{availableFlat?.dailyPrice} <span>/ night</span>
                </span>
                {/* <span
                  className="reviews"
                  onClick={() => {
                    handleViewMore(0);
                  }}
                >
                  {reviewData.totalReviews || 0} Reviews
                </span> */}

                <span className="overallReviews">
                  ★ {reviewData.overallRating.toFixed(1)}
                </span>
              </div>
              {showButton && (
                <button
                  className="reserve-button"
                  onClick={() => handleReserve && handleReserve()}
                >
                  Reserve Now
                </button>
              )}
            </div>
          )}
        </div>
      </nav>
      {showMore && (
        <AllReviews
          showMore={showMore}
          handleClose={closeAllReview}
          reviews={reviewData.reviews}
          ratings={reviewData.rating}
          reviewWrapper={reviewWrapper}
          defaultReview={Number(defaultReviewId)}
        />
      )}
    </>
  );
};

export default HotelDetailsNav;
