import { useState } from "react";
import { toast } from "react-toastify";
import { FiMail, FiUpload } from "react-icons/fi";
import {
  FaWhatsapp,
  FaRegCopy,
  FaFacebookSquare,
  FaTwitterSquare,
  FaEllipsisH,
} from "react-icons/fa";
import useCheckMobileDevice from "../../helpers/useCheckMobileDevice";
import Modal from "../../shared-components/Modal";
import styles from "./style.module.scss";
import minilogo from "../../assets/minilogo.png";

export const loadFacebookSdk = () => {
  return new Promise<void>((resolve) => {
    if (window.FB) {
      resolve();
    } else {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId: "YOUR_FACEBOOK_APP_ID",
          autoLogAppEvents: true,
          xfbml: true,
          version: "v13.0",
        });
        resolve();
      };

      (function (d, s, id) {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        const js = d.createElement(s) as HTMLScriptElement;
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode?.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    }
  });
};

type Props = {
  title: string;
  url: string;
  image: string;
  description: any;
  waText: string;
};

const Share = (props: Props) => {
  const isMobile = useCheckMobileDevice();
  const [showShareModal, setShowShareModal] = useState(false);
  console.log("image", props.image);

  const copyWebSite = () => {
    if (navigator) {
      navigator.clipboard.writeText(props.url);

      toast.success("Link Copied!");
    }
  };

  const shareOnFB = async () => {
    await loadFacebookSdk();
    FB.ui(
      {
        method: "share",
        href: props.url,
      },
      function () {}
    );
  };

  const shareOnTwitter = () => {
    const hashtags = "blablastay";
    const twitterLink =
      "https://twitter.com/intent/tweet?hashtags=" +
      hashtags +
      "&url=" +
      encodeURIComponent(props.url) +
      "&text=" +
      encodeURIComponent(props.title) +
      "&via=blablastay";
    window.open(
      twitterLink,
      "",
      "left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
    );
  };

  const shareOnWhatsApp = () => {
    const sharableText = encodeURIComponent(
      `*${props.title}*\r\n${props.waText}\r\n\nTo know more, Please Visit ${props.url}`
    );

    window.open(
      "https://web.whatsapp.com/send/?text=" + sharableText,
      "WhatsApp Share",
      "left=0,top=0,width=1080,height=900,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
    );
  };

  const shareViaEmail = () => {
    const encodedSubject = encodeURIComponent(props.title);
    const encodedBody = encodeURIComponent(
      `${props.waText}\n\nPlease Visit ${props.url} to know more about it.\n\nThanks\n\n`
    );
    const emailUrl = `mailto:?subject=${encodedSubject}&body=${encodedBody}`;
    window.location.href = emailUrl;
  };

  const inAppShare = () => {
    if (navigator.share) {
      navigator
        .share({
          url: props.url,
          text: `*${props.title}*\r\n${props.description}`,
        })
        .catch(console.error);
    } else {
      console.log("Your browser doesn't support navigator.share!");
    }
  };

  return (
    <div className={styles.shareWrapper}>
      <div
        className={styles.shareBtn}
        onClick={() => {
          isMobile ? inAppShare() : setShowShareModal(true);
        }}
      >
        <FiUpload /> <u>Share</u>
      </div>
      {!isMobile && showShareModal && (
        <Modal
          visible={showShareModal}
          heading={"Share this place"}
          handleClose={() => setShowShareModal(false)}
          className={styles.modal}
        >
          <div className={styles.detailWrapper}>
            <img src={minilogo} alt="hotel" className={styles.image} />
            <div>
              <h3 className={styles.title}>{props.title}</h3>
              <span className={styles.desc}>{props.description}</span>
            </div>
          </div>
          <div className={styles.btnWrapper}>
            <button
              className={`${styles.btn} ${styles.copy}`}
              onClick={copyWebSite}
              title="Copy Link"
            >
              <i className={styles.icon}>
                <FaRegCopy />
              </i>{" "}
              Copy Link
            </button>
            {/* <button
              className={`${styles.btn} ${styles.fb}`}
              onClick={shareOnFB}
              title='Share on Facebook'
            >
              <i className={styles.icon}><FaFacebookSquare /></i> Facebook
            </button> */}
            {/* <button
              className={`${styles.btn} ${styles.twitter}`}
              onClick={shareOnTwitter}
              title='Share on Twitter'
            >
              <i className={styles.icon}><FaTwitterSquare /></i> Twitter
            </button> */}
            <button
              className={`${styles.btn} ${styles.email}`}
              onClick={shareViaEmail}
              title="Share via Email"
            >
              <i className={styles.icon}>
                <FiMail />
              </i>{" "}
              Email
            </button>
            <button
              className={`${styles.btn} ${styles.whatsApp}`}
              onClick={shareOnWhatsApp}
              title="Share on Whatsapp"
            >
              <i className={styles.icon}>
                <FaWhatsapp />
              </i>{" "}
              WhatsApp
            </button>
            <button
              className={`${styles.btn} ${styles.ellipse}`}
              onClick={inAppShare}
              title="More options for sharing"
            >
              <i className={styles.icon}>
                <FaEllipsisH />
              </i>{" "}
              More Options
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Share;
