import React, { useEffect, useRef, useMemo } from "react";
import {
  Map as MapLibreMap,
  NavigationControl,
  Marker,
  Popup,
} from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import BlaBlaStayLogo from "../../../../assets/logo64.png";
import { Feature, Point } from "geojson";

const olaApiKey = process.env.REACT_APP_OLA_API_KEY;

interface OlaMapComponentProps {
  lat: number;
  lng: number;
}

const OlaMapComponent: React.FC<OlaMapComponentProps> = ({ lat, lng }) => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapInstanceRef = useRef<MapLibreMap | null>(null); // Keep track of the map instance

  const getApproximateCoordinates = (lat: number, lng: number) => {
    const offset = 0.01;
    return {
      lat: lat + (Math.random() - 0.5) * offset,
      lng: lng + (Math.random() - 0.5) * offset,
    };
  };

  const approximateCoordinates = useMemo(
    () => getApproximateCoordinates(lat, lng),
    [lat, lng]
  );

  useEffect(() => {
    if (!mapContainerRef.current || mapInstanceRef.current) return;

    const mapInstance = new MapLibreMap({
      container: mapContainerRef.current,
      center: [approximateCoordinates.lng, approximateCoordinates.lat],
      zoom: 13,
      style:
        "https://api.olamaps.io/tiles/vector/v1/styles/default-light-standard/style.json",
      // style:
      //   "https://api.olamaps.io/styleEditor/v1/styleEdit/styles/870e9407-0be6-46c1-9b7d-84e7d43401cf/BlaBlaStayMap",
      transformRequest: (url, resourceType) => {
        if (url.includes("?")) {
          url += `&api_key=${olaApiKey}`;
        } else {
          url += `?api_key=${olaApiKey}`;
        }
        return { url, resourceType };
      },
    });

    mapInstance.addControl(new NavigationControl(), "top-left");

    const logoElement = document.createElement("div");
    logoElement.style.width = "50px"; // Increase the size for better visibility
    logoElement.style.height = "50px";
    logoElement.style.backgroundColor = "red"; // Circular background color
    logoElement.style.borderRadius = "50%"; // Make the div circular
    logoElement.style.display = "flex"; // Use flexbox for centering
    logoElement.style.alignItems = "center"; // Vertically center the content
    logoElement.style.justifyContent = "center"; // Horizontally center the content

    // Add the logo as a child element
    const imageElement = document.createElement("img");
    imageElement.src = BlaBlaStayLogo;
    imageElement.style.width = "60%";
    imageElement.style.height = "60%";
    imageElement.style.objectFit = "contain";

    imageElement.style.filter = "invert(50%) brightness(200%)";

    logoElement.appendChild(imageElement);

    const popup = new Popup({
      closeButton: false,
      closeOnClick: false,
    }).setHTML(
      '<span style="font-weight: bold; white-space: nowrap;">Exact location provided after booking.</span>'
    );

    const logoMarker = new Marker({ element: logoElement })
      .setLngLat([approximateCoordinates.lng, approximateCoordinates.lat])
      .addTo(mapInstance);

    logoElement.addEventListener("mouseenter", () => {
      popup.setLngLat([approximateCoordinates.lng, approximateCoordinates.lat]);
      popup.addTo(mapInstance);
    });

    logoElement.addEventListener("mouseleave", () => {
      popup.remove();
    });

    const radiusInMeters = 1000;
    const circleGeoJson: Feature<Point> = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [approximateCoordinates.lng, approximateCoordinates.lat],
      },
      properties: { radius: radiusInMeters },
    };

    mapInstance.on("load", () => {
      if (!mapInstance.getSource("circle")) {
        mapInstance.addSource("circle", {
          type: "geojson",
          data: circleGeoJson,
        });

        mapInstance.addLayer({
          id: "circle-fill",
          type: "circle",
          source: "circle",
          paint: {
            "circle-radius": [
              "interpolate",
              ["exponential", 2],
              ["zoom"],
              10,
              10,
              16,
              1000,
            ],
            "circle-color": "rgba(255, 0, 0, 0.3)",
            "circle-opacity": 0.5,
          },
        });
      }
    });

    mapInstanceRef.current = mapInstance;

    // Force resize after the map is initialized
    setTimeout(() => {
      mapInstance.resize();
    }, 0);

    return () => {
      mapInstance.remove();
      logoMarker.remove();
      mapInstanceRef.current = null;
    };
  }, [approximateCoordinates, lat, lng]);

  // Handle map resizing
  useEffect(() => {
    const handleResize = () => {
      mapInstanceRef.current?.resize();
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <h5>Where you’ll be</h5>
      <div style={{ position: "relative", width: "100%", height: "400px" }}>
        <div ref={mapContainerRef} style={{ width: "100%", height: "100%" }} />
      </div>
    </>
  );
};

export default OlaMapComponent;
