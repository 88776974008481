import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import HotelDetails from "./HotelDetailsPage";
import { formatDate } from "utilities/DateTime";
import {
  formatDateUsingArray,
  todayDate,
  tomorrowDate,
} from "utilities/ConvertDateFormatUsingArray";
import { AppDispatch } from "helpers/GenericType";
import useCheckMobileDevice from "helpers/useCheckMobileDevice";
import { getQueryParamsFromUrl } from "utilities/HelperFunctions";
import {
  getHotelDetails,
  getAvailablity,
  updateSearchParams,
} from "store/actions";
import { navigateTo, Navigation } from "helpers/Navigation";

function HotelDetailsPageContainer() {
  const history = useHistory();
  const { hotelId, subPropertyId }: any = useParams();
  const isMobile = useCheckMobileDevice();
  const dispatch: AppDispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [showAvailabilityLoader, setShowAvailabilityLoader] = useState(false);
  const { search, hotel }: any = useSelector((state) => state);

  // Function to retrieve search data from localStorage
  const loadSearchDataFromLocalStorage = () => {
    const localSearch = localStorage.getItem("search");
    if (localSearch) {
      const parsedSearch = JSON.parse(localSearch);
      dispatch(updateSearchParams(parsedSearch)); // Dispatch to Redux
    }
  };

  const getParams = (id: string = hotelId, flatId: any = undefined) => {
    let params = {
      propertyId: id,
      flatId: flatId,
      checkIn: search.checkInDate ? formatDate(search.checkInDate) : undefined,
      checkOut: search.checkOutDate
        ? formatDate(search.checkOutDate)
        : undefined,
      ofAdults: search.adult == "0" ? undefined : search.adult,
      ofChildren: search.children == "0" ? undefined : search.children,
      ofInfants: search.infant == "0" ? undefined : search.infant,
    };
    const queryParams = getQueryParamsFromUrl();
    if (queryParams) {
      params = {
        propertyId: id,
        flatId: flatId,
        checkIn:
          queryParams.checkInDate !== ""
            ? formatDateUsingArray(queryParams.checkInDate)
            : undefined,
        checkOut:
          queryParams.checkOutDate !== ""
            ? formatDateUsingArray(queryParams.checkOutDate)
            : undefined,
        ofAdults: queryParams.adult != 0 ? queryParams.adult : undefined,
        ofChildren: queryParams.adult != 0 ? queryParams.children : undefined,
        ofInfants: queryParams.adult != 0 ? queryParams.infant : undefined,
      };
    }
    const totalGuests =
      (Number(params.ofAdults) || 0) + (Number(params.ofChildren) || 0);
    if (totalGuests === 0) {
      params.ofAdults = 1;
    }
    return params;
  };

  const loadHotelDetails = (id: string = hotelId, flatId: any = undefined) => {
    setShowAvailabilityLoader(true);
    const apiParams = {
      propertyId: id,
      subPropertyId: flatId,
    };
    setShowLoader(true);
    dispatch(getHotelDetails(apiParams)).finally(() => setShowLoader(false));
  };

  const checkAvailablity = (id: string = hotelId, flatId: any = undefined) => {
    let params = {
      propertyId: id,
      subPropertyId: flatId,
      checkIn: search.checkInDate ? formatDate(search.checkInDate) : undefined,
      checkOut: search.checkOutDate
        ? formatDate(search.checkOutDate)
        : undefined,
      ofAdults: search.adult == "0" ? 0 : search.adult,
      ofChildren: search.children == "0" ? 0 : search.children,
      ofInfants: search.infant == "0" ? 0 : search.infant,
    };
    const queryParams = getQueryParamsFromUrl();
    if (queryParams) {
      params = {
        propertyId: id,
        subPropertyId: flatId,
        checkIn:
          queryParams.checkInDate !== ""
            ? formatDateUsingArray(queryParams.checkInDate)
            : todayDate(),
        checkOut:
          queryParams.checkOutDate !== ""
            ? formatDateUsingArray(queryParams.checkOutDate)
            : tomorrowDate(),
        ofAdults: queryParams.adult != 0 ? queryParams.adult : 0,
        ofChildren: queryParams.adult != 0 ? queryParams.children : 0,
        ofInfants: queryParams.adult != 0 ? queryParams.infant : 0,
      };
    }
    const totalGuests =
      (Number(params.ofAdults) || 0) + (Number(params.ofChildren) || 0);
    if (totalGuests === 0) {
      params.ofAdults = 1;
    }
    setShowAvailabilityLoader(true);
    dispatch(getAvailablity(params))
      .catch((err: any) => {})
      .finally(() => setShowAvailabilityLoader(false));
  };

  useEffect(() => {
    // Load search data from localStorage when component mounts
    loadSearchDataFromLocalStorage();

    if (hotel?.hotelDetails?.parent?.subPropertyId) {
      checkAvailablity(
        hotel?.hotelDetails?.parent?.propertyId,
        hotel?.hotelDetails?.parent?.subPropertyId
      );
    }
  }, [hotel?.hotelDetails?.parent?.subPropertyId]);

  useEffect(() => {
    const queryParams = getQueryParamsFromUrl();
    if (queryParams) {
      const apiParams = {
        propertyId: hotelId,
        subPropertyId: subPropertyId,
      };
      setShowLoader(true);
      dispatch(getHotelDetails(apiParams)).finally(() => setShowLoader(false));
    }
  }, []);

  return (
    <HotelDetails
      isMobile={isMobile}
      showLoader={showLoader}
      showAvailabilityLoader={showAvailabilityLoader}
      checkAvailablity={checkAvailablity}
      details={hotel.hotelDetails}
      loadHotelDetails={loadHotelDetails}
    />
  );
}

export default HotelDetailsPageContainer;
