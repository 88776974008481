import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import UserImg from "../../../../assets/businessman.png";

type Props = {
  propertyMaster: any;
};

function HostDetails(props: Props) {
  return (
    <div className="host-details-container mt-4">
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <h4>
            {props.propertyMaster.subPropertyTypeName} in{" "}
            {props.propertyMaster.propertyTypeName} hosted by{" "}
            {props.propertyMaster.hostName}
          </h4>
          <h5 className="f-16 mb-0">
            {props.propertyMaster.subPropertyConfig}
          </h5>
        </div>
        <a>
          <img className="image" src={UserImg} />
        </a>
      </div>
    </div>
  );
}

export default HostDetails;
