import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Images from "utilities/Images";
import "./UpdatePaymentStatus.scss";
import { FormButton } from "shared-components";
import { useHistory } from "react-router";
import { navigateTo, Navigation } from "helpers/Navigation";
import HeaderSection from "components/headerSection";
import FooterSection from "components/footer-section/FooterSection";

const host = process.env.REACT_APP_GUEST_NEW_DOT_NET_URL;

const UpdatePaymentStatus = () => {
  const location = useLocation();
  const [statusMessage, setStatusMessage] = useState(
    "Updating payment status..."
  );
  const history = useHistory();

  const handleBookings = () => navigateTo(Navigation.myBookings, history);

  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    return {
      bookingNumber: searchParams.get("bookingnumber"),
      razorpayPaymentId: searchParams.get("razorpay_payment_id"),
      razorpayPaymentLinkId: searchParams.get("razorpay_payment_link_id"),
      razorpayPaymentLinkReferenceId: searchParams.get(
        "razorpay_payment_link_reference_id"
      ),
      razorpayPaymentLinkStatus: searchParams.get(
        "razorpay_payment_link_status"
      ),
      razorpaySignature: searchParams.get("razorpay_signature"),
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = getQueryParams();
      const { bookingNumber } = queryParams;

      if (bookingNumber) {
        try {
          var apiUrl = `${host}api/Booking/UpdatePaymentStatus/${bookingNumber}`;

          const response = await axios.get(apiUrl);
          if (response.status === 200) {
            setStatusMessage(
              "Your Payment is recivied successfully by BlaBlaStay!!!"
            );
          } else {
            setStatusMessage(
              "Failed to update payment status. Please try again."
            );
          }
        } catch (error) {
          setStatusMessage("Error while updating payment status.");
          console.error("API Error:", error);
        }
      } else {
        setStatusMessage("Invalid or missing booking number.");
      }
    };

    fetchData();
  }, [location]);

  return (
    <div>
      <HeaderSection activeNavbar={false} hideSearchOnInitialRender={false} />
      <div className="m-4 row justify-space-between">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div style={{ padding: "50px" }}>
            <h1 style={{ color: "green", fontSize: "20px" }}>
              {statusMessage}
            </h1>
            <p style={{ color: "black", fontSize: "15px", fontWeight: "bold" }}>
              You can login and see your booking details in My Booking section.
            </p>
            <div style={{ width: "200px" }}>
              <FormButton
                text="My Bookings"
                onClick={() => handleBookings()}
                type="button"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <img src={Images.success} className="image-style" alt="successIMG" />
        </div>
      </div>
      <FooterSection />
    </div>
  );
};

export default UpdatePaymentStatus;
