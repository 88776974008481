import "./style.scss";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { FormButton } from "shared-components";
import { navigateTo, Navigation } from "helpers/Navigation";
import { getQueryParamsFromUrl } from "utilities/HelperFunctions";

type Props = {
  showButton: boolean;
  handleReserve?: () => void;
};

function BillInformation(props: Props) {
  const history = useHistory();
  const { hotel }: any = useSelector((state) => state);
  const { hotelId }: any = useParams();

  const availabileFlat = hotel.hotelDetails?.availableFlat || {};

  const handleClick = () => {
    const queryParams = getQueryParamsFromUrl();

    navigateTo(
      Navigation.hotelDetails,
      history,
      { location_id: hotelId },
      {
        ...queryParams,
        flat_id: availabileFlat.flatId,
      },
      true
    );
  };

  return availabileFlat.isAvailable ? (
    <div className="billing-information-container">
      <div className="m-2 d-flex justify-content-between">
        <div className="type">
          ₹{availabileFlat.basePrice} x {availabileFlat.nights}{" "}
          {availabileFlat.nights === 1 ? "day" : "days"}
        </div>
        <div className="amount">
          ₹{(availabileFlat.basePrice * availabileFlat.nights).toFixed(2)}
        </div>
      </div>
      {/* <div className="m-2 d-flex justify-content-between">
        <div className="type">Base Price</div>
        <div className="amount">₹{availabileFlat.basePrice}</div>
      </div>
      <div className="m-2 d-flex justify-content-between">
        <div className="type">Rooms</div>
        <div className="amount">{availabileFlat.roomsRequired}</div>
      </div> */}
      <div className="m-2 d-flex justify-content-between">
        <div className="type">Platform Fee</div>
        <div className="amount">₹{availabileFlat.internet}</div>
      </div>
      <div className="m-2 d-flex justify-content-between">
        <div className="type">Extra Bed Charges</div>
        <div className="amount">{availabileFlat.extraBeddingPrice}</div>
      </div>
      {/* <div className="m-2 d-flex justify-content-between">
        <div className="type">Total Days</div>
        <div className="amount">{availabileFlat.nights}</div>
      </div> */}
      <hr />
      <div className="m-2 d-flex justify-content-between">
        <div className="type-bold">Total after taxes</div>
        <div className="amount-bold">
          ₹{Number(availabileFlat.totalPrice).toFixed(2)}
        </div>
      </div>

      {props.showButton && (
        <FormButton
          text="Reserve now"
          type="button"
          onClick={() => props.handleReserve && props.handleReserve()}
        />
      )}
    </div>
  ) : (
    <div className="notAvailable">
      <b>Property not available in this date.</b>
      <br />
      <b>Please select other date.</b>
      <br />
      {availabileFlat.flatId ? (
        <label>
          To see the availabile flat in the same property, Please{" "}
          <span className="flatLink" onClick={handleClick}>
            {" "}
            Click Here
          </span>
        </label>
      ) : (
        ""
      )}
    </div>
  );
}

export default BillInformation;
