import React, { Fragment } from "react";
import HotelItem from "./HotelItem";

type Props = {
  hotels: any[];
  locationName: string;
  search: object;
  handleClick: (
    id: number,
    subPropertyId: number,
    adult: number,
    checkInDate: string,
    checkOutDate: string
  ) => void;
};

function HotelList(props: Props) {
  const isEmpty = props.hotels.length === 0;
  console.log(props.search);

  return (
    <div className="hotel-listing-page">
      {!isEmpty ? (
        <Fragment>
          <h6 className="count">{props.hotels.length}+ stays</h6>
          <h2 className="heading">Stays in {props.locationName}</h2>
          <div className="row mt-4">
            {props.hotels.map((hotel: any) => (
              <div
                key={hotel.id}
                className="col-lg-4 col-md-6 col-sm-12 col-xs-12"
              >
                <HotelItem
                  hotelDetails={hotel}
                  search={props.search}
                  handleClick={props.handleClick}
                />
              </div>
            ))}
          </div>
        </Fragment>
      ) : (
        <div className="text-center mt-2">
          <h2>
            <strong>No Hotels found.</strong>
          </h2>
          <p>No Hotels found for this location</p>
        </div>
      )}
    </div>
  );
}

export default HotelList;
