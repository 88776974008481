import { useEffect, useState } from "react";
import Reviews from "../../reviews";
import About from "./About";
import Policies from "./Policies";
import Amenities from "./Amenities";
import HostDetails from "./HostDetails";
import TimeDetails from "./TimeDetails";
import MapLocation from "./MapLocation";
import Arrangements from "./Arrangements";
import SimilarHotels from "./SimilarHotels";
import ImageGalleryContainer from "./ImageGallery";
import HotelDetailsHeader from "./HotelDetailsHeader";
import EnquiryFormContainer from "components/common-components/EnquiryForm";
import HightlightSection from "./HightlightSection";
import RoomPolicies from "./RoomPolicies/RoomPolicies";
import MapboxView from "./MapView/MapView";
import MapView from "./MapView/MapView";
import OlaMapView from "./MapView/OlaMapView";
import HotelDetailsNav from "./Nav/HotelDetailsNav";

type Props = {
  flatId: string;
  hotelDetails: any;
  isMobile: boolean;
  handleReserve: () => void;
  handleAvailability: () => void;
  handleGroupProperty: (subPrppertyId: any) => void;
  handleIndividualProperty: (hote: any) => void;
  showLoader?: boolean;
  checkInDate: string;
  checkOutDate: string;
  handleCheckIndate: (date: any) => void;
  handleCheckOutdate: (date: any) => void;
  handleGuests: (action: string, type: string) => void;
  guests: {
    adult: number;
    children: number;
    infant: number;
    [key: string]: number;
  };
  reviews?: any;
  getAndSetReviews: (count?: number, avgRating?: number) => void;
};

function HotelDetails(props: Props) {
  const details = props.hotelDetails.parent;
  const images = details ? details.images : [];
  const policies = details?.policies || [];
  const amenities = details?.amenities || [];
  const propertyMaster = details || {};
  const subPropertyData = props.hotelDetails.child || [];
  const activities = details?.activities || [];
  const broadband = details?.broadBands || [];
  const kitchennfood = details?.kitchens || [];
  const sleepingArrangements = details?.sleepingArrangement || [];
  const reviewData = details?.reviews || {};
  const lat = details?.latitude || 0;
  const lng = details?.longitude || 0;

  const [showNav, setShowNav] = useState(false);
  const [showReserveButton, setShowReserveButton] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    const displayItem2 = document.querySelector(".displayItem2");
    const navbarHeight = 70; // Adjust for actual navbar height

    // Show Reserve Button when the form is scrolled past
    if (displayItem2) {
      const rect = displayItem2.getBoundingClientRect();
      setShowReserveButton(rect.bottom <= navbarHeight);
    }

    // Show nav when scrolling down past threshold, hide when scrolling up
    const scrollThreshold = 500;
    if (currentScrollY > scrollThreshold) {
      setShowNav(true); // Show nav if scrolled past the threshold
    } else {
      setShowNav(false); // Hide nav if not
    }

    // If scrolling up, hide the nav bar
    if (currentScrollY < lastScrollY) {
      setShowNav(false); // Hide nav bar on scroll up
    }

    // Update last scroll position
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Cleanup scroll listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array, no need for lastScrollY

  return (
    <>
      <HotelDetailsNav
        flatId={props.flatId}
        showButton={true}
        showNav={showNav}
        showReserveButton={showReserveButton}
        reviewData={reviewData}
        handleReserve={props.handleReserve}
      />
      <HotelDetailsHeader
        reviews={reviewData}
        propertyMaster={propertyMaster}
      />
      <section id="gallery">
        <ImageGalleryContainer isMobile={props.isMobile} images={images} />
      </section>

      <div className="displayFlex">
        <div className="displayItem">
          <HostDetails propertyMaster={propertyMaster} />
          <About
            propertyMaster={propertyMaster}
            description={propertyMaster.about || ""}
          />
          <section id="amenities">
            <Amenities amenities={amenities} />
          </section>
          <Arrangements
            activities={activities}
            broadband={broadband}
            kitchenFood={kitchennfood}
            sleepingArrangements={sleepingArrangements}
          />
        </div>
        <div className="displayItem2">
          <div className="hotel-detail-enquiry">
            <EnquiryFormContainer
              showButton={true}
              handleReserve={props.handleReserve}
              checkAvailability={props.handleAvailability}
              reviews={null}
              showLoader={props.showLoader}
              checkInDate={props.checkInDate}
              checkOutDate={props.checkOutDate}
              handleCheckIndate={props.handleCheckIndate}
              handleCheckOutdate={props.handleCheckOutdate}
              guests={props.guests}
              handleGuests={props.handleGuests}
            />
          </div>
        </div>
      </div>

      <section id="reviews">
        <Reviews flatId={props.flatId} reviewData={reviewData} />
      </section>

      <RoomPolicies
        propertyMaster={propertyMaster}
        checkInDate={props.checkInDate}
      />
      <section id="location">
        <OlaMapView lat={lat} lng={lng} />
      </section>

      <SimilarHotels
        showGroup="1"
        flatId={propertyMaster.subPropertyId}
        propertyMaster={propertyMaster}
        subPropertyData={subPropertyData}
        handleGroupProperty={props.handleGroupProperty}
        handleIndividualProperty={props.handleIndividualProperty}
      />
    </>
  );
}

export default HotelDetails;
