export const Navigation = {
  home: "/",
  hlp: "/hotels/:locationId",
  hotelDetails: "/hotelDetails/:hotelId/:subPropertyId",
  bookingConfirmation: "/booking/:hotelId/:locationId",
  signup: "/signup",
  login: "/login",
  myBookings: "/my-bookings",
  profile: "/profile",
  bookingDetails: "/booking/:bookingId",
  paymentDetails: "/payment/:bookingId",
  footerLinkPage: "/page/:id",
  hostDetailsPage: "/host-details",
  reviewPage: "/review",
  updatePaymentStatus: "/confirmation/updatepayment",
};

export const navigateTo = (
  url: string,
  history: { push: Function },
  params?: any,
  queryParams?: any,
  openInNewTab: boolean = false
) => {
  let tempUrl = url;

  if (params) {
    const tempParams = { ...params };
    const arr = Object.values(tempParams);
    const splitArray = tempUrl.split("/");

    for (let i = 0; i < arr.length; i++) {
      splitArray.pop();
    }

    tempUrl = splitArray.join("/") + "/" + arr.join("/");
    console.log("Constructed URL with params:", tempUrl);
  }

  if (queryParams) {
    const str = [];
    for (let p in queryParams) {
      str.push(`${p}=${queryParams[p]}`);
    }
    tempUrl = tempUrl + "?" + str.join("&");
    console.log("Constructed URL with queryParams:", tempUrl);
  }

  if (openInNewTab) {
    const fullUrl = `${window.location.origin}${tempUrl}`;
    window.open(fullUrl, "_blank");
    console.log("Opening in new tab:", fullUrl);
  } else {
    history.push(tempUrl);
  }
};
